import axios from "axios"
import { backendUrl, getAxiosConfig } from "../config"

export interface Location {
    locationId: string
    locationType: string
    description: string
    clickAndCollect: boolean
    ecom: boolean
    promotionMatchers?: PromotionMatcher[]
    validityIntervals: string[]
}

export interface PromotionMatcher {
    typeId: string
    themeId: string
    chain: string
}

export const getLocations = async (token: string) => {
    const path = backendUrl + "/service/retail-inventory-visibility-v2/locations"
    const response = await axios.get(path, getAxiosConfig(token))
    if (response.status === 200) {
        return response.data as Location[]
    }
    throw new Error("Failed to load locations")
}
